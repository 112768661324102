.manage-user-container {
  .manage-user-img {
    background-image: url('/images/backgroundManageUser.png') !important;
    background-size: cover !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
  }

  .manage-user-title {
    margin-top: 3%;
    font-family: DM Sans;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 42px;
    letter-spacing: 0px;
    text-align: center;
    color: #1c1d21;
  }

  .manage-user-subtitle {
    font-family: DM Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0px;
    text-align: center;
    color: #8181a5;
  }
  .manage-user-actions {
    margin-top: 2%;
    width: 100%;
    display: flex;
    padding-bottom: 3%;
    .manage-user-input-container {
      width: 60%;
      .manage-user-input {
        font-family: DM Sans;
        font-size: 24px;
        float: right;
        background-color: white;
        width: 65%;
        height: 64px;
        padding: 31px;
        ::placeholder {
          font-family: DM Sans;
          font-size: 24px;
          font-style: normal;
          font-weight: 400;
          line-height: 31px;
          letter-spacing: 0em;
          color: #828282;
        }
      }
    }
    .manage-user-button-container {
      width: 40%;
      .manage-user-input {
        float: left;
        width: 35%;
        height: 64px;
        background-color: #5e81f4;
        color: white;
        font-family: DM Sans;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 31px;
        letter-spacing: 0em;
        &:hover {
          // background-color: #2fb7cc;
          opacity: 0.8;
        }
      }
    }
  }
}

.agent-row {
  cursor: pointer;
  .incidents-summary-go {
    visibility: hidden;
  }
  &:hover {
    background: linear-gradient(0deg, rgba(94, 129, 244, 0.05), rgba(94, 129, 244, 0.05)), #ffffff;
    .incidents-summary-go {
      cursor: pointer;
      visibility: visible;
    }
  }
}

.top-agents-box {
  width: 50%;
  // margin-right: 2.5%;
  background: #ffffff;
  border: 1px solid #dadada;
  border-radius: 10px;
  // padding: 2% 0 0 2%;
}

.agent-container {
  display: flex;
  width: 100%;
  .agent-content {
    width: 80%;
    .agent-back-container {
      position: relative;
      width: 48px;
      height: 48px;
      border-radius: 4px;
      background-color: #5e81f4;
      cursor: pointer;
      .agent-button {
        color: white;
        transform: translate(0%, 50%);
      }
    }
    .agent-view-container {
      text-align: -webkit-center;
      margin-top: 5% !important;
      .agent-view-img {
        background-image: url('/images/filterView.png') !important;
        background-size: cover !important;
        background-position: center !important;
        background-repeat: no-repeat !important;
        height: 25em;
        width: 50%;
      }
      .container-agent-message-text {
        margin-top: 3%;
        .agent-message-text {
          font-family: 'DM Sans';
          font-size: 24px;
          font-style: normal;
          font-weight: 400;
          line-height: 27px;
          letter-spacing: 0px;
          text-align: center;
          color: #4f4f4f;
        }
      }
    }
  }
  .agent-searchBar {
    width: 20%;
    .agent-searchBar-content {
      margin-top: 10%;
      justify-content: center;
    }
  }
}

.centered-loader {
  width: 100%;
  display: flex;
  justify-content: center;
}

.agent-cases-table-container {
  padding: 24px 0 !important;
}

.agent-sentiment {
  display: flex;
  border-radius: 4px;
  .text {
    margin-left: 8px;
    margin-top: 2px;
    margin-bottom: 2px;
    margin-right: 5px;
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 20px;
    /* identical to box height, or 200% */
    align-self: center;
    letter-spacing: 0.03em;
    text-transform: uppercase;

    color: #ffffff;
    // .politeness {
    //   .MuiLinearProgress-barColorPrimary {
    //     background-color: red;
    //   }
    // }
  }
}
.politeness {
  .MuiLinearProgress-barColorPrimary {
    background-color: #f178b6;
  }
}
.persuading {
  .MuiLinearProgress-barColorPrimary {
    background-color: #6fcf97;
  }
}
.lesspolite {
  .MuiLinearProgress-barColorPrimary {
    background-color: #eb5757;
  }
}
.embarrassment {
  .MuiLinearProgress-barColorPrimary {
    background-color: #f2994a;
  }
}
.confidence {
  .MuiLinearProgress-barColorPrimary {
    background-color: #219653;
  }
}
.disbelief {
  .MuiLinearProgress-barColorPrimary {
    background-color: #f2c94c;
  }
}
.formality {
  .MuiLinearProgress-barColorPrimary {
    background-color: #a5a6f6;
  }
}
.uncategorized {
  .MuiLinearProgress-barColorPrimary {
    background-color: #d9d9d9;
  }
}
