.progress-bar-container {
  display: flex;
  align-items: center;
  white-space: nowrap;
  .negative-bar .MuiLinearProgress-barColorPrimary {
    background-color: #e9573e;
  }
  .positive-bar .MuiLinearProgress-barColorPrimary {
    background-color: #6dc69a;
  }
}
.progress-bar-container.negative-status {
  color: #e9573e;
}
.progress-bar-container.positive-status {
  color: #6dc69a;
}
