.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #6dc69a;
}

.text-white {
  color: #fff;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.card-basic-container {
  height: 40vw;
}
.card-table-container {
  box-shadow: none;
}

.width-mc {
  width: max-content !important;
}

.max-width-mc {
  max-width: max-content !important;
}

.m-auto {
  margin: auto !important;
}

.align-items-center {
  align-items: center !important;
}

.justify-content-center {
  justify-content: center !important;
}

.align-self-center {
  align-self: center !important;
}

.d-flex {
  display: flex;
}

.flex-row {
  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}

.float-right {
  float: right;
}

:root {
  --iris-message-color: #f4f4f4;
}

.w-100 {
  width: 100% !important;
}

.w-50 {
  width: 50%;
}

.ml-auto {
  margin-left: auto !important;
}

.mr-auto {
  margin-right: auto !important;
}

.width-fc {
  width: fit-content;
}

.mt-1 {
  margin-top: 1em;
}

.m-0 {
  margin: 0 !important;
}

.mb-1 {
  margin-bottom: 1em;
}

.mb-1o5 {
  margin-bottom: 1.5em;
}

.mb-2 {
  margin-bottom: 2em;
}

.gap {
  gap: 20px;
}

.p-0 {
  padding: 0 !important;
}

.p-0o5 {
  padding: 0.5em !important;
}

.h-100 {
  height: 100%;
}

.width-fill-available {
  width: -webkit-fill-available !important;
  width: -moz-available !important;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.right-0 {
  right: 0;
}

.border-0 {
  border: 0;
}

.word-break {
  word-break: break-word;
}

.width-unset {
  width: unset !important;
}

.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.minh-100 {
  min-height: 100%;
}
