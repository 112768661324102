.sort-class {
  cursor: pointer;
  // display: flex;
  // align-items: center;
  font-size: 0.875rem;
  color: white;
  font-weight: 600;
  border-radius: 8px;
  color: black;
  margin-right: 16px;
  z-index: 100;

  .sort-button {
    background: linear-gradient(0deg, rgba(94, 129, 244, 0.1), rgba(94, 129, 244, 0.1)), #ffffff;
    color: '#8181A5';

    height: 40;
    cursor: 'pointer';
    border-radius: 4px;
    border-width: 0px;
    margin-right: 12px;

    .sort-button-text {
      margin-top: 10px;
      margin-bottom: 10px;
      margin-left: 10px;
      margin-right: 10px;

      font-family: 'DM Sans';
      font-style: normal;
      font-weight: 700;
      font-size: 10px;
      line-height: 13px;
      text-transform: uppercase;
    }
  }
}
