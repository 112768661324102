.platform-login {
  background-color: unset;
  display: flex !important;
  justify-content: center !important;
  margin-top: 4rem;
  .platform-row {
    margin-top: 5rem;
    .platform-form {
      width: 40rem;
      padding: 10px;
    }
    .platform-div {
      display: flex;
      flex-direction: row;
    }
  }
  .sub-header {
    font-size: 11px;
    margin-bottom: 15px;
  }
}
.schedule-demo-box {
  background: linear-gradient(249.26deg, #275aff 0%, #5d5fef 69.27%);
  display: flex;
  flex-direction: row;
  border: 1px solid #2f80ed;
  border-radius: 40px;
  margin-top: 20px;
  width: '100%';
  margin-left: 200px;
  margin-right: 200px;
  .inner-box {
    display: flex;
    flex-direction: column;
    padding: 40px 80px;
    color: white;
    text-align: left;
  }
  .image {
    margin-top: 1%;
    margin-right: 8%;
    margin-left: auto;
    max-height: 240px;
  }
}
.logout-button {
  margin-top: 1% !important;
  background: rgb(94, 129, 244) !important;
  color: #ffffff !important;
  float: right;
  margin-right: 1% !important;
}

.common-container {
  font-weight: normal;
  text-align: left;

  a {
    color: revert;
  }
}
