.granularDataContainer {
  display: flex;
  padding: 3%;
  .box {
    width: 56px;
    height: 56px;
    border-radius: 10px;
  }
  .dashLine {
    position: static;
    width: auto;
    height: 0px;
    border: 0.5px solid #828282;
    flex: none;
    order: 1;
    flex-grow: 0;
    margin-top: 12px;
  }
}
