.kb-accordian {
  // min-height: 33px !important;
  border-radius: 0px;
  // height: 33px;
  // box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
}
.item-id {
  border-radius: 4px;
  color: #5e81f4 !important;
  font-weight: 700 !important;
}
.kb-tabs {
  .MuiTab-textColorInherit.Mui-selected {
    background-color: transparent;
  }
  .MuiTabs-fixed {
    height: 35px !important;
    min-height: 35px !important;
    margin-left: 32px;
  }

  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
  background: #f7f7fe;
  width: 100%;
  align-self: center;
  min-height: 35px !important;
  height: 35px !important;
  .kb-tab {
    padding: 0;
    height: 35px;
    min-height: 35px;
    .kb-tab-heading {
      font-size: 12px;
      text-transform: uppercase;
      font-weight: 700;
      color: #5d5fef;
    }
  }
}
.MuiAccordionSummary-content {
  margin: 0 !important;
  align-items: center;
}
.MuiAccordionSummary-root.Mui-expanded {
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}
.MuiAccordion-rounded:last-child {
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
}
.MuiAccordion-rounded:first-child {
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}
.MuiTabs-indicator {
  background-color: #5e81f4 !important;
}
// .MuiTabs-root {
//   height: 35px !important;

//   min-height: 35px !important;
// }
.category-metrics {
  border-left-color: #5d81f3;
  border-left-width: 2px;
  border-left-style: solid;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
