.simple-timeline {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 20px;
  width: auto;
  height: 80px;
  .timeline-node {
    display: flex;
    align-items: center;
  }
  .timeline-dot-container {
    width: 15px;
    height: 15px;
    border: 3px solid #5e81f4;
    border-radius: 50%;
    box-sizing: border-box;
  }
  .timeline-connector-container {
    position: relative;
    width: 170px;
    border: 1px dashed #5e81f4;
  }
}
