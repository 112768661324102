.category-class {
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  color: white;
  font-weight: 600;
  border-radius: 8px;
  color: black;
  padding-left: 20px;
  margin-inline-end: auto !important;

  .category-button {
    // background: linear-gradient(0deg, rgba(94, 129, 244, 0.1), rgba(94, 129, 244, 0.1)), #FFFFFF;
    // color: '#8181A5';

    height: 40;
    cursor: 'pointer';
    border-radius: 4px;
    border-width: 0px;
    margin-right: 12px;
    //margin-top: 6px;

    .category-button-text {
      margin-top: 6px;
      margin-bottom: 6px;
      margin-left: 8px;
      margin-right: 8px;

      font-family: 'DM Sans';
      font-style: normal;
      font-weight: 700;
      font-size: 10px;
      line-height: 13px;
      text-transform: uppercase;
    }
  }

  // .category-button:focus-visible {
  //   background: black;
  // }
}
