.main-task-title {
  font-size: 16px !important;
  margin: 28px;

  color: #8181a5;
}
.task-title {
  font-size: 22px !important;
  margin: 0px !important;
  padding: 0px !important;
  align-self: center;
  color: #000000 !important;
  font-weight: 500 !important;
}
.created {
  margin-top: 16px !important;
  font-size: 14px;
  color: #8181a5;
  font-weight: 400;
  margin-bottom: 26px !important;
}

.small-labels {
  line-height: 18px !important;
  font-size: 14px !important;
  color: #8181a5 !important;
  font-weight: 500 !important;
  margin-bottom: 4px !important;
}
.large-labels {
  font-size: 18px !important;
  line-height: 27px !important;
  color: #000000 !important;
  font-weight: 400 !important;
  margin-bottom: 8px !important;
}
.status {
  background: linear-gradient(0deg, rgba(94, 129, 244, 0.1), rgba(94, 129, 244, 0.1)), #ffffff;
  border-radius: 8px;
  padding: 10px 25px;
  color: #1b51e5;
}
.horizontal-line {
  margin-top: 26px !important;
  margin-bottom: 26px;
}
.description-box {
  border-radius: 8px;
  padding: 10px;
  background: #f6f6f6;
  font-size: 14px;
  font-weight: 400;

  /* grey 3 */

  //   color: #828282 !important;
}
.done-button {
  background: linear-gradient(0deg, #5e81f4, #5e81f4), #ffffff;
  border-radius: 8px !important;
  color: white !important;
  padding: 12px 34px !important;
}
.cancel-button {
  background: linear-gradient(0deg, rgba(94, 129, 244, 0.1), rgba(94, 129, 244, 0.1)), #ffffff;
  border-radius: 8px !important;
  color: #5d5fef !important;
  padding: 12px 34px !important;
}
