.revenue-container {
  padding: 16px;
  display: flex;
  flex-direction: column;
  background: white;
  border-radius: 10px;
  width: inherit;

  .heading {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 27px;
    text-align: left;
    // margin-bottom: 16px;
  }
  .item {
    background: linear-gradient(0deg, rgba(94, 129, 244, 0.05), rgba(94, 129, 244, 0.05)), #ffffff;
    border-radius: 10px;
    margin-right: 12px;
    min-height: 88px;
    width: 168px;
    padding: 16px;
    text-align: left;
    .text {
      font-family: 'DM Sans';
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 31px;

      color: #000000;
    }
    .content {
      font-family: 'DM Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
      line-height: 21px;
      margin-left: 4px;
      align-self: center;
      color: #6fcf97;
    }
    .label {
      margin-top: 4px;
      font-family: 'DM Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      /* identical to box height, or 150% */

      color: #8181a5;
    }
  }
}
