.btnContainer {
  background-color: white;
  display: flex;
  align-items: center;

  width: 400px;
  height: 50px;

  margin: auto auto 1em;
  border: 1px solid #e7e9eb;
  border-radius: 8px;

  .button {
    font-size: 13px;
    width: 100%;
    color: #5d5fef;
    font-weight: 700;
    background-color: white;
  }

  .button:hover {
    background-color: unset;
  }

  .iconButton {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;

    .icon {
      width: 34px;
      height: 34px;
    }
  }
}
