.title-chart-items {
  text-align: left;
  font-family: DM Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0px;
  color: #4f4f4f;
}

.grid-container {
  display: grid;
  grid-template-columns: auto auto;
}
.grid-item {
  font-family: DM Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0px;
  display: flex;
  align-items: center;
  padding-top: 5%;
  margin-right: 20px;
  cursor: pointer;
  color: #333333;
}
