.alert-container {
  .center-content {
    .MuiAlert-icon {
      margin-left: auto;
    }
  }
  .MuiAlert-standardSuccess {
    background-color: #cef0ce;
  }
}
