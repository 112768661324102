.loading {
  left: 50%;
  margin-top: 10%;
}
.text-spinner-container {
  margin-top: 13px;

  a {
    text-decoration: underline;

    &:link {
      color: blue;
    }

    &:visited {
      color: darkslateblue;
    }
  }
}
