.health-account-container {
  display: flex;
  width: 100%;
  .health-account-content {
    width: 80%;
    .health-account-back-container {
      position: relative;
      width: 48px;
      height: 48px;
      border-radius: 4px;
      background-color: #5e81f4;
      cursor: pointer;
      .health-account-button {
        color: white;
        transform: translate(0%, 50%);
      }
    }
    .customer-view-container {
      text-align: -webkit-center;
      margin-top: 5% !important;
      .customer-view-img {
        background-image: url('/images/filterView.png') !important;
        background-size: cover !important;
        background-position: center !important;
        background-repeat: no-repeat !important;
        height: 25em;
        width: 50%;
      }
      .container-customer-message-text {
        margin-top: 3%;
        .customer-message-text {
          font-family: DM Sans;
          font-size: 24px;
          font-style: normal;
          font-weight: 400;
          line-height: 27px;
          letter-spacing: 0px;
          text-align: center;
          color: #4f4f4f;
        }
      }
    }
  }
  .health-account-searchBar {
    width: 20%;
    .health-account-searchBar-content {
      margin-top: 10%;
      display: flex;
      justify-content: center;
    }
  }

  // .customer-view-container {
  //   text-align: -webkit-center;
  //   margin-top: 5% !important;
  //   .customer-view-img {
  //     background-image: url('/images/filterView.png') !important;
  //     background-size: cover !important;
  //     background-position: center !important;
  //     background-repeat: no-repeat !important;
  //     height: 25em;
  //     width: 50%;
  //   }
  //   .container-customer-message-text {
  //     margin-top: 3%;
  //     .customer-message-text {
  //       font-family: DM Sans;
  //       font-size: 24px;
  //       font-style: normal;
  //       font-weight: 400;
  //       line-height: 27px;
  //       letter-spacing: 0px;
  //       text-align: center;
  //       color: #4f4f4f;
  //     }
  //   }
  // }
}
