.filter-box {
  display: flex;
  justify-content: center;
  // height: 40px;
  background: rgba(237, 237, 237, 0.4);
  border-radius: 4px;
  z-index: 9;
}

.filter-menu {
  z-index: 1;
  color: #333333;
  font-size: 14px;
  line-height: 17px;
  font-weight: normal;
  height: 46px;
  display: flex;
}

.filter-menu:hover {
  color: #5e81f4;
  font-weight: bold;
}

.list-item-icon {
  min-width: fit-content !important;
  margin-right: 8px;
}
