.login-footer {
  padding: 10px 20px 10px;
  background: linear-gradient(65deg, #1e253f 0%, #2d385e 100%);
  background-size: cover;
  text-align: left;

  span {
    line-height: 25px;
    font-size: 13px;
    span {
      margin-right: 10px;
      text-decoration: none;
    }
  }
}
