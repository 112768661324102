.root {
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  color: white;
  font-weight: 600;
  border-radius: 8px;
  color: black;
  .calendar-icon {
    color: #6e7172;
  }
}
.react-datepicker-wrapper {
  width: inherit;
}
