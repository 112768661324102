.header-footer-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header-footer-container > div {
  width: -moz-available; /* WebKit-based browsers will ignore this. */
  width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
}
