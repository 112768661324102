.main {
  margin: 0;
  padding: 0;

  .accordian {
    box-shadow: none;
    width: 360px;
    background-color: #f6f6f6;
    min-height: 34px !important;
    height: 34px;

    .accordian-heading {
      font-family: 'DM Sans';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 18px;
      color: #8181a5;
    }
  }
}
