.button {
  // background: linear-gradient(0deg, rgba(94, 129, 244, 0.1), rgba(94, 129, 244, 0.1)), #FFFFFF;
  // color: '#8181A5';

  height: 21px;
  cursor: 'pointer';
  // border-radius: 4px;
  border-width: 0px;
  min-width: fit-content;

  .button-text {
    margin-top: 4px;
    margin-bottom: 4px;
    margin-left: 8px;
    margin-right: 8px;

    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    line-height: 13px;
  }
}
