.back_button_details {
  border-radius: 4px;

  & svg {
    color: white;
  }
}

.button_details {
  box-shadow: none !important;
  min-width: 120px !important;
  height: 40px;
  margin: 16px !important;
  background-color: #5e81f4 !important;
  border-radius: 4px;
  text-transform: none !important;
  .MuiButton-label {
    color: white;
    .MuiButton-startIcon {
      margin-left: 0 !important;
    }
  }
}

.button_details_disable {
  opacity: 0.2;
  height: 40px;
  margin-top: 16px !important;
  margin-bottom: 16px !important;
  background-color: #5e81f4 !important;
  border-radius: 4px;
  text-transform: none !important;
  .MuiButton-label {
    color: white;
    .MuiButton-startIcon {
      margin-left: 0 !important;
    }
  }
}

.button_details_cancel {
  box-shadow: none !important;
  height: 40px;
  min-width: 120px !important;
  border: 1px solid #5e81f4 !important;
  margin-top: 16px !important;
  margin-bottom: 16px !important;
  background-color: #ffffff !important;
  border-radius: 4px;
  text-transform: none !important;
  .MuiButton-label {
    color: #5d5fef;
    .MuiButton-startIcon {
      margin-left: 0 !important;
    }
  }
}

.created_by_text {
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0px;
  color: #4f4f4f;
}

.footer_details_rule {
  width: 100%;
  border-radius: 0 !important;
  background-color: #eff2fd;
  margin-top: auto;
}

.created-by-text {
  width: fit-content;
  min-height: 32px;

  word-wrap: break-word;
  white-space: nowrap;
  font-family: DM Sans;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 32px;
  text-align: right;
  color: #4f4f4f;
  white-space: nowrap;
}
.white-container {
  padding: 0 !important;
  //min-width: 60vh;
  height: fit-content;
  //min-height: 600px;
  display: flex;
  flex-direction: column;
  .child {
    padding: 24px;
    padding-bottom: 0px;
  }
}
.edit-button {
  margin-left: 12px !important;
  padding: 3px !important;
  .MuiSvgIcon-root {
    color: #bdbdbd;
  }
}

.condition-action-container {
  display: flex;
  flex-direction: row;
  gap: 32px;
  align-items: baseline;
  margin: 0 auto;
  flex-wrap: wrap;
  margin-top: 2em;

  .tool-root {
    width: -moz-available; /* WebKit-based browsers will ignore this. */
    width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
  }
}

.condition-action-container > div {
  margin: 0 !important;
  flex-basis: 47%;
}

.main-container {
  flex-basis: 100%;
}

.trigger-paper {
  min-height: 30vh;
}

@media screen and (min-width: 1400px) {
  .condition-action-container {
    flex-direction: column;
    flex-basis: 25%;
    flex-wrap: unset;
    min-height: 0;
    height: 100%;
    margin-top: 0;
  }

  .condition-action-container > div {
    flex-basis: 100%;
  }

  .main-container {
    width: unset;
    flex-basis: 75%;
  }

  .white-container {
    margin-right: 34px;
  }

  .trigger-paper {
    min-height: 80vh;
  }
}
