.filter-class {
  cursor: pointer;
  // display: flex;
  // align-items: center;
  font-size: 0.875rem;
  color: white;
  font-weight: 600;
  border-radius: 8px;
  color: black;
  margin-right: 16px;

  .filter-button {
    background: linear-gradient(0deg, rgba(94, 129, 244, 0.1), rgba(94, 129, 244, 0.1)), #ffffff;
    color: #8181a5;

    height: 40;
    cursor: 'pointer';
    border-radius: 4px;
    border-width: 0px;
    margin-right: 12px;

    .filter-button-text {
      margin-top: 10px;
      margin-bottom: 10px;
      margin-left: 10px;
      margin-right: 10px;

      font-family: 'DM Sans';
      font-style: normal;
      font-weight: 700;
      font-size: 10px;
      line-height: 13px;
      text-transform: uppercase;
    }
  }
}

.filter-options {
  padding: 0px !important;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 0px;
  margin-bottom: 4px;
}

.footer {
  // bottom: 0;
  // position: absolute;
  // right: 0;
  text-align: end;
  background: #f4f4f4;
  height: 64px;
  width: 360px;
  padding-top: 12px;
  padding-right: 24px;
  position: fixed;
  bottom: 0;
  // width: 100%;

  .ok-button {
    background: linear-gradient(0deg, #5e81f4, #5e81f4), #ffffff;
    border-radius: 8px;
    color: #f2f2f2;
    padding: 8px 20px;
  }

  .cancel-button {
    background: linear-gradient(0deg, rgba(94, 129, 244, 0.1), rgba(94, 129, 244, 0.1)), #ffffff;
    border-radius: 8px;
    color: #5d5fef;
    margin-right: 16px;
    padding: 8px 20px;
  }
}

.advanced-filter {
  background-color: #2f80ed;

  height: 89px;
  width: auto;
  padding-top: 20px;
  padding-left: 30px;

  .heading {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 32px;
    /* identical to box height, or 160% */

    /* Gray 6 */

    color: #f2f2f2;
  }

  .sub-heading {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height, or 150% */

    /* Gray 6 */

    color: #f2f2f2;
  }

  .accordian {
    box-shadow: none;

    .accordian-heading {
      font-family: 'DM Sans';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 18px;
      /* identical to box height, or 112% */

      color: #8181a5;
    }
  }
}
