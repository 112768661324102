.button_details {
  height: 48px;
  background-color: #5e81f4 !important;
  border-radius: 4px;
  text-transform: none !important;
  .MuiButton-label {
    color: white;
    .MuiButton-startIcon {
      margin-left: 0 !important;
    }
  }
}

.button_icon {
  width: 20px;
  height: 20px;
  color: white;
}

.rules-trigger-table-container {
  // margin won't work because of the Stack Component,
  // which has priority over the margin definitions.
  margin-top: 15px !important;
}

.add-condition-button {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;

  align-items: center;
  display: flex;
  justify-content: center;

  color: #5d5fef;

  width: 125px;
  height: 40px;
  background: linear-gradient(0deg, rgba(94, 129, 244, 0.1), rgba(94, 129, 244, 0.1)), #ffffff !important;
  border-radius: 8px;
}

.add-condition-button:hover {
  cursor: pointer;
}
