.connect-organization-container {
  margin: 0 auto;
  padding: 25px;
  width: 250px;
  .connect-button {
    background-color: #6dc69a;
    color: white;
    margin-top: 10px;
  }
  .connect-button:hover {
    background-color: #2fb7cc;
    color: white;
  }
  .error {
    color: red;
    float: left;
    font-size: 14px;
  }
}
