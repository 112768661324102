.table-with-tabs-table-container {
  width: 100%;
  height: 100%;

  color: #383838;
  font: 'DM Sans', sans-serif;

  table {
    width: 100%;
    height: 100%;
    padding: 20px;
    border-radius: 12px;
  }

  thead {
    height: 150px;

    .table-with-tabs-table-head-content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    th {
      color: #878787;
      font-family: 'DM Sans', sans-serif;
      font-style: normal;
      font-size: 14px;
      line-height: 24px;
      font-weight: 500;
    }
  }

  .table-with-tabs-table-head-title {
    height: 32px;
  }

  .table-with-tabs-table-head-buttons {
    display: flex;
    align-items: center;

    .rule-triggers-table-head-button-container + .rule-triggers-table-head-button-container {
      margin-left: 8px;
    }
  }

  .rules-trigger-table-body-loading {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
