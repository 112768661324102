.table-row-sorting {
  background-color: #eff2fd;
  text-transform: uppercase;
  .table-header.sorting-bold {
    font-weight: bold;
  }

  .table-header.sorting-small-size {
    font-size: 12px;
  }

  .table-header.sorting-uppercase {
    text-transform: uppercase;
  }

  .table-header.sorting-less-padding {
    padding: 5px 16px;
  }

  .activeSortIcon {
    font-weight: 600;
    font-size: 12px;
  }

  .inactiveSortIcon {
    opacity: 0.6;
    font-size: 12px;
  }
}
.sortIcon {
  margin: 10px;
  margin-top: 18px;
}
.MuiTable-root {
  width: 100% !important;
  table-layout: fixed;
  .MuiTableRow-root {
    cursor: pointer;
    &:hover {
      background: linear-gradient(0deg, rgba(94, 129, 244, 0.05), rgba(94, 129, 244, 0.05)), #ffffff;
    }
  }
  .MuiTableCell-root {
    border: none;
  }
  .MuiTableCell-stickyHeader {
    background-color: #eff2fd;
  }
}
