.login-jira-container {
  margin: 0 40px;
  Button {
    margin-top: 10px;
  }
  .instructions {
    margin-right: auto;
    text-align: left;
  }
  .login-jira-button {
    background-color: #6dc69a;
    border-radius: 8px !important;
    padding: 12px 34px !important;
    color: white;
    width: 350px;
  }
  .login-jira-button:hover {
    background-color: #2fb7cc;
    color: white;
  }
  .cancel-button {
    width: 350px;
  }
  .subdomain-container {
    display: flex;
    align-items: center;
    .domain-input {
      background-color: #f9f9f9;
      border-radius: 4px;
      flex: 1;
    }
    .jira-domain {
      cursor: default;
      display: inline-block;
      font-size: 16px;
      margin: 0;
      right: 1px;
      top: 6px;
      background-color: #f9f9f9;
      border-radius: 0 5px 5px 0;
    }
    .https-domain {
      cursor: default;
      font-size: 16px;
      margin: 0;
      padding: 5px;
      padding-left: 0;
      background-color: #f9f9f9;
      border-radius: 0 5px 5px 0;
    }
  }
  .error {
    color: red;
    float: left;
    font-size: 14px;
    font-weight: 600;
  }
}
