.tab-container .max-support-container {
  .max-support-header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
    .left-container {
      display: flex;
      align-items: center;
      justify-content: space-around;
      .title-table.chart-title {
        display: flex;
        padding: 16px 0px;
        font-size: 18px;
        color: #383838;
        font-weight: 500;
        font-family: 'DM Sans', sans-serif;
        border-bottom: none !important;
      }
    }
    .sub-title-table {
      font-size: 14px;
      color: rgba(56, 56, 56, 0.63);
      font-weight: 500;
    }
    .sub-title-table.left-margin {
      margin-left: 50px;
    }
    .dash-line {
      position: static;
      width: 220px;
      height: 0px;
      left: 0px;
      top: 72px;
      border: 0.5px solid #828282;
      flex: none;
      order: 1;
      flex-grow: 0;
      margin: 16px 0px;
    }
  }

  .incidents-container {
    .incident-container {
      border-left: 4px solid #25d2d8;
      box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
      text-align: left;
      background-color: #f8f9fe;
      padding: 22px;
      .incident-header {
        display: flex;
        .incident-header-left {
          width: 70%;
          margin-right: 10px;
          .incident-id {
            color: #1ad89f;
            font-size: 11px;
            .incident-id-value {
              margin-left: 3px;
              font-weight: 600;
            }
          }
          .incident-title {
            display: -webkit-box;
            line-height: 1.43;
            -webkit-line-clamp: 1; /* Lines to show */
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        .incident-header-right {
          font-size: 14px;
          width: fit-content;
          padding: 0px 15px;
          height: 40px;
          border-radius: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: rgba(255, 156, 6, 0.12);
          font-weight: 500;
          .cases-label {
            color: #ff9c06;
          }
          .cases-number {
            color: white;
            border-radius: 20px;
            width: 23px;
            height: 23px;
            margin-left: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
      .incident-date {
        font-size: 12px;
        color: rgba(62, 68, 80, 0.53);
        margin: 20px 0px;
        font-weight: 600;
      }
      .cases-table-container {
        .cases-table-cell {
          padding: 6px;
          .casesLink {
            color: #52b3d7;
            font-weight: bold;
            text-decoration: none;
            padding: 3px 8px;
            margin-right: 5px;
            cursor: pointer;
          }
        }
        .cases-table-cell.case-number {
          font-size: 11px;
          border-bottom: none;
        }
        .cases-table-cell.subject {
          font-size: 13px;
          border-bottom: none;
          .case-ellipsis {
            display: -webkit-box;
            line-height: 1.43;
            -webkit-line-clamp: 1; /* Lines to show */
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        .normal-row {
        }
        .second-row {
          .casesLink {
            border-radius: 15px;
          }
        }
      }
      .cases-footer-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 30px;
        margin-top: 18px;
        border-top: 1px solid #e7ecf2;
        .view-details-button {
          color: #1aabd8;
          font-weight: bold;
          cursor: pointer;
        }
      }
    }
    .incident-container:hover {
      border-left: 4px solid #5e81f4;
      background-color: #f0f2fe;
    }
  }
  .view-container {
    margin-top: 35px;
    display: flex;
    justify-content: space-around;
    .view-items-container {
      cursor: pointer;
      width: 100px;
      .view-label {
        font-size: 12px;
        color: rgba(56, 56, 56, 0.63);
        font-weight: 500;
      }
      .view-icon {
        margin-top: 10px;
      }
    }
  }
}
