.query-input::placeholder,
.query-input::-webkit-input-placeholder {
  margin-left: 1em;
  margin-top: auto;
  margin-bottom: auto;
}

.query-input {
  margin-top: 0;
  flex-grow: 1;
  padding-left: 1em;
  padding-top: 0.5em;
}

.circular-progress-color {
  color: grey;
}

#iris-agent-chatbot-div {
  li {
    list-style-position: inside;
  }

  a {
    color: revert;
    text-decoration: revert;
  }

  font-weight: normal;

  * {
    font-weight: inherit;
  }
}
