.priority-box-container {
  justify-content: center;
  align-items: center;

  .High {
    background-color: rgba(235, 87, 87, 0.1);
    color: #eb5757;
    padding: 8px;
  }

  .Normal {
    background-color: rgba(244, 190, 94, 0.1);
    color: #f2c94c;
    padding: 8px;
  }

  .Low {
    background-color: rgba(3, 187, 115, 0.1);
    color: #219653;
    padding: 8px;
  }
}
