.incident-tab-container {
  font-family: 'DM Sans', sans-serif;
}
.incidents-title .MuiCardHeader-title {
  text-align: left;
  font-size: 18px;
  color: #383838;
  font-weight: 500;
  padding-bottom: 5px;
  font-family: 'DM Sans', sans-serif;
}
.container-incidents-steps {
  .MuiMobileStepper-root {
    display: none;
  }
}
.incidents-details-container {
  .incident-details-header-container {
    display: flex;
    margin: 0px 10px;
    justify-content: space-between;
    align-items: center;
    .back-button-container {
      margin-left: 12px;
    }
    .problem-ticket-container {
      margin-right: 12px;
    }
    .problem-ticket-link {
      display: flex;
      font-size: 15px;
    }
    .basic-button {
      background-color: #30aabc;
      color: white;
      height: 36px;
      &:hover {
        background-color: #2fb7cc;
      }
    }
    .incident-details-subheader-container-right {
      display: flex;
      align-items: center;
    }
  }
}
.processing-data {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80vh;
  flex-direction: column;
  .documentation-button {
    background-color: #30aabc;
    color: white;
  }
}
.tabs {
  .tab {
    .heading {
      font-size: 18px;
      text-transform: none;
      font-weight: bold;
      color: #5d5fef;
    }
  }
}
.MuiTab-textColorInherit.Mui-selected {
  background-color: #efeffd;
}
.PrivateTabIndicator-colorSecondary-5 {
  background-color: #5e81f4 !important;
}
