.graph-container {
  //   padding-bottom: 20px;
  margin-top: 20px !important;
}
.customTooltip {
  background: white;
  color: #333;
  border: 1px solid #ccc;
  padding: 1px;
  text-align: center;
  //   border-radius: 12px;

  .tooltipDetails {
    margin: 13px 19px;
  }

  .label {
    font-weight: 600;
  }
}
