.platform-login {
  background-color: unset;
  .platform-row {
    margin-top: unset;
    .platform-form {
      width: 40rem;
      padding: 10px;
    }
  }
  .sub-header {
    font-size: 11px;
    margin-bottom: 15px;
  }
}
