.footer-di {
  // bottom: 0;
  // position: absolute;
  // right: 0;
  text-align: end;
  background: #f4f4f4;
  height: 64px;
  width: 100%;
  padding-top: 12px;
  padding-right: 24px;
  position: fixed;
  bottom: 0;
  // width: 100%;
  width: -moz-available; /* WebKit-based browsers will ignore this. */
  width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */

  .ok-button {
    background: linear-gradient(0deg, #5e81f4, #5e81f4), #ffffff;
    border-radius: 8px;
    color: #f2f2f2;
    padding: 8px 20px;
  }

  .cancel-button {
    background: linear-gradient(0deg, rgba(94, 129, 244, 0.1), rgba(94, 129, 244, 0.1)), #ffffff;
    border-radius: 8px;
    color: #5d5fef;
    margin-right: 16px;
    padding: 8px 20px;
  }
}

.advanced-filter {
  background-color: #2f80ed;

  height: 89px;
  width: auto;
  padding-top: 20px;
  padding-left: 30px;

  .heading {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 32px;
    /* identical to box height, or 160% */

    /* Gray 6 */

    color: #f2f2f2;
  }

  .sub-heading {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height, or 150% */

    /* Gray 6 */

    color: #f2f2f2;
  }

  .accordian {
    box-shadow: none;

    .accordian-heading {
      font-family: 'DM Sans';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 18px;
      /* identical to box height, or 112% */

      color: #8181a5;
    }
  }
}

.trending-incidents-settings-drawer {
  width: 70%;
}
.trending-incidents-settings-drawer-bottom {
  padding-left: 20px;
  padding-right: 20px;
}

.configure-button {
  border-radius: 4px !important;
  display: flex !important;
  border-color: #5d5fef !important;
  color: #5d5fef !important;

  .MuiButton-label {
    gap: 4px;
  }
}

.page-container {
  padding: 2%;
}
