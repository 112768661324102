.parent {
  display: flex;
  width: 100%;
  .icon-button {
    color: 'red !important';
    margin-right: 12px;
  }
  .box {
    border: 1px solid #a5a6f6;
    border-radius: 4px;
    width: inherit;
    justify-content: left;
    font-family: 'DM Sans';
    font-style: normal;
    text-transform: unset;
    font-weight: 500;
    line-height: 24px;
    color: #4f4f4f;
    flex-grow: 0;
  }
}
