.save_settings_button_details {
  box-shadow: none !important;
  min-width: 120px !important;
  //width: 40% !important;
  height: 40px;
  margin: 16px !important;
  background-color: #5e81f4 !important;
  border-radius: 4px;
  text-transform: none !important;
  .MuiButton-label {
    color: white;
    .MuiButton-startIcon {
      margin-left: 0 !important;
    }
  }
}
