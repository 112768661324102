.manage-user-container {
  .manage-user-img {
    background-image: url('/images/backgroundManageUser.png') !important;
    background-size: cover !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
  }

  .manage-user-title {
    margin-top: 3%;
    font-family: DM Sans;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 42px;
    letter-spacing: 0px;
    text-align: center;
    color: #1c1d21;
  }

  .manage-user-subtitle {
    font-family: DM Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0px;
    text-align: center;
    color: #8181a5;
  }
  .manage-user-actions {
    margin-top: 2%;
    width: 100%;
    display: flex;
    padding-bottom: 3%;
    .manage-user-input-container {
      width: 60%;
      .manage-user-input {
        font-family: DM Sans;
        font-size: 24px;
        float: right;
        background-color: white;
        width: 65%;
        height: 64px;
        padding: 31px;
        ::placeholder {
          font-family: DM Sans;
          font-size: 24px;
          font-style: normal;
          font-weight: 400;
          line-height: 31px;
          letter-spacing: 0em;
          color: #828282;
        }
      }
    }
    .manage-user-button-container {
      width: 40%;
      .manage-user-input {
        float: left;
        width: 35%;
        height: 64px;
        background-color: #5e81f4;
        color: white;
        font-family: DM Sans;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 31px;
        letter-spacing: 0em;
        &:hover {
          // background-color: #2fb7cc;
          opacity: 0.8;
        }
      }
    }
  }
}
