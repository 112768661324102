.sub-text {
  font-size: 0.875rem;
  text-align: left;
  margin-left: 90px;
}

.login-main-title {
  font-size: 2.5em;
  line-height: 1.4;
  margin-top: 0;
  font-weight: bold;
  text-align: left;
}

.login-title {
  font-size: 2em;
  line-height: 1.4;
  margin-top: 0;
  font-weight: normal;
  text-align: left;
  margin-bottom: 1em;
}

.login-para {
  font-size: 1.15em;
  line-height: 1.4;
  margin-top: 0;
  font-weight: normal;
  text-align: left;
}

.app-login {
  width: 100%;
  height: 100%;
  font-weight: normal;

  main {
    height: calc(100% - 45px);
    padding: 50px;

    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-grow: 1;

    overflow: auto;

    &::-webkit-scrollbar {
      width: 1rem;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      height: 56px;
      border-radius: 16px;
      border: 4px solid transparent;
      background-clip: content-box;
      background-color: #5e81f4;
    }
  }
}
