.manage-accounts-container {
  box-sizing: border-box;
  .incident-source-button {
    background-color: #5e81f4;
    display: block;
    color: white;
    margin-top: 15px;
    margin-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
    text-transform: none;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
  }
  .button {
    width: 304px;
    height: fit-content;
  }
  .incident-source-button:hover {
    background-color: #2fb7cc;
    color: white;
  }
  .error {
    color: red;
    float: left;
    font-size: 14px;
  }
  .connection-info {
    font-size: 15px;
    padding: 2px;
    color: #6dc69a;
  }
  .disable-button {
    cursor: no-drop;
    border: 1px solid #6fcf97;
    background-color: white;
    border-radius: 8px;
    color: #6fcf97;
    &:hover {
      background-color: white;
      color: #6fcf97;
    }
  }

  .container-card-account {
    display: grid;
    row-gap: 1%;
    column-gap: 1%;
    grid-template-columns: repeat(3, minmax(334px, 1fr));
    grid-template-rows: repeat(2, 246px);
    background-color: white;
    padding-top: 25px;
    padding-bottom: 25px;
    .card-account {
      position: relative;
      background: linear-gradient(0deg, rgba(94, 129, 244, 0.05), rgba(94, 129, 244, 0.05)), #ffffff;
      border-radius: 8px;
      .card-image {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 75.48px;
        height: 75.44px;
        margin: 10px;
        background: #ffffff;
        border: 1px solid #efefef;
        border-radius: 8px;
        margin-left: 15px;
        margin-top: 15px;
      }
    }
  }
}

.manage-accounts-back-button {
  display: flex;
  margin: 0px 10px;
  justify-content: space-between;
  .basic-button {
    background-color: #30aabc;
    color: white;
    &:hover {
      background-color: #2fb7cc;
    }
  }
}
