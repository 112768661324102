.reporting-service-container {
  .card-header-container {
    display: flex;
    font-size: 300px;
  }
  .container-reporting {
    display: flex;
    .table-container-reporting {
      width: 40%;
      .MuiTableContainer-root {
        box-shadow: none;
        height: 100%;
        .MuiTableCell-root {
          border-bottom: none;
          padding: 0px;
        }
      }
    }
    .timeline-container {
      width: auto;
      margin-top: -260px;
      margin-left: 26%;
      margin-bottom: -260px;
    }
    .ticketLink {
      background-color: #ebfbff;
      color: #52b3d7;
      font-weight: bold;
      text-decoration: none;
      padding: 3px 8px;
      margin-right: 5px;
      cursor: pointer;
    }
  }
  .title-table {
    font-weight: bold;
    border-bottom: 1px solid #ececec;
  }
}
