.accordian {
  min-height: 48px !important;
  border-radius: 8px;
  height: 48px;
  border-radius: 8px;
  .accordian-icon {
    padding: 0;
    margin: 0;
    max-width: fit-content;
    width: fit-content;
    min-width: fit-content;
    pointer-events: none;
  }
}
.MuiAccordionSummary-root.Mui-expanded {
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}
.tool-item {
  padding: 0px !important;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 0px;
  margin-bottom: 4px;
}
