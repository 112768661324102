.card-table-container {
  .MuiCardContent-root {
    padding: 0px;
    &:last-child {
      padding-bottom: 0px;
    }
  }
}
.tab-container .negative-score-table-container {
  margin-top: 32px;
}

.dont-break-out {
  overflow-wrap: break-word;
  word-wrap: break-word;

  word-break: break-word;

  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}
