.badge-card-container {
  width: 30%;
  font-size: 20px;
  font-weight: 400;
  .badge-card-container-value {
    display: flex;
    align-items: center;
    .container-avatar {
      background-color: #1faed5;
      font-size: 1rem;
      width: 50px;
      height: 50px;
      .type {
        font-size: 12px;
      }
    }
    .container-avatar-type {
      display: inline-grid;
      .value {
        margin-top: 3px;
      }
      .type {
        margin-top: -8px;
      }
    }
    .container-text {
      margin-left: 12px;
    }
  }
  .badge-card-container-value:last-child {
    padding-bottom: 16px;
  }
}
